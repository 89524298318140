
import { defineComponent } from "vue";
import { getRandomCard } from "../services/CardService";
export default defineComponent({
  name: "StoryPart",
  props: ["section", "description", "types"],
  data() {
    return {
      cardUri: "",
      loading: true
    };
  },
  methods: {
    setCard() {
      this.loading = true;
      getRandomCard(this.types).then((value: any) => {
        this.cardUri = value.image_uris.normal;
        this.loading = false;
      });
    },
  },
  created() {
    this.setCard();
  },
});
